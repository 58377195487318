import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }) => {
  const [scrollInstance, setScrollInstance] = useState(null);
  const scrollInstanceRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
    import('locomotive-scroll').then(LocomotiveScroll => {
      const noScroll = document.querySelector('#noScroll');
      const scrollEl = document.querySelector('#main-app');
      if (scrollEl) {
        if (!noScroll) {
          
          const locomotiveScroll = new LocomotiveScroll.default({
            el: scrollEl,
          smooth: true,
        lerp: 0.1,
      });
    setScrollInstance(locomotiveScroll);
    scrollInstanceRef.current = locomotiveScroll;
    }
      }
    });

    return () => {
      if (scrollInstanceRef.current) {
        scrollInstanceRef.current.destroy();
      }
    };
  }
  }, []);

  return (
    <ScrollContext.Provider value={scrollInstance}>
     {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};