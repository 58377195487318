import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router';

import Logo from '../../images/maytronics-mineralswim-logo.png'

import '../../index.css'
import './style.css'

import Header from '../Header'
import ActionBar from '../ActionBar'
import Footer from '../Footer'
import { ScrollProvider } from './ScrollContext';

import BrochureForm from '../BrochureForm/BrochureForm.js'

/**
 * Layout - Wrapping your pages in top level goodness
 * @param {Object} props
 * @param {Node} props.children
 */
const Layout = ({children, data }) => {
	const [isFormModalOpen, setIsFormModalOpen] = useState(false)
	const [formType, setFormType] = useState("")
	const [email, setEmail] = useState("")
	const location = useLocation();

	useEffect(() => {
		const parseQueryString = (search) => {
			const params = new URLSearchParams(search);
			const action = params.get('action');
			return { action };
	};
	const { action } = parseQueryString(location.search);
	if (action === 'download-brochure') {
		setIsFormModalOpen(true);
		setFormType('indexBrochure')
	} else {
		setIsFormModalOpen(false);	
	}
	}, [location.pathname, location.search]);
		return (
			<>
{isFormModalOpen && <div  style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex:"999999" }}className='fixed inset-0 w-full h-full flex justify-center items-center'>
				<div className='backdrop-blur-lg bg-white p-5 rounded-md mx-5'>
				<BrochureForm email={email} formType={formType} setFormType={setFormType} setIsFormModalOpen={setIsFormModalOpen}/>
				</div>
				</div>}
			<ScrollProvider>
		<div>
	<div className="bg-white grid w-full z-50" style={{ height: 63 }}>
	<div className="fixed bg-white w-full z-40 ">
		<Header logo={Logo} setIsFormModalOpen={setIsFormModalOpen}/>
	</div>
	{/* <ActionBar /> */}
</div>
		<div id='main-app'>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Mineral Swim - Experience The Dead Sea</title>
				<meta
					name="description"
					content="Mineral Swim Is A Truly Unique Swimming Experience. Providing An Authentic Dead Sea Experience With Thousands Of Years Of Health And Beauty Straight Into Your Pool. Maytronics, A Leading Supplier To The Pool Industry For Over 35 Years, Brings The Highest Quality Minerals To Recreate An Authentic Dead Sea Experience In Your Very Own Pool."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
				<meta name="keywords" content="Mineral Swim" />
				<link rel="canonical" href="https://mineralswim.com/" />
				<meta name="twitter:site" content="@MaytronicsAu" />
				<meta name="og:type" content="website" />
				<meta
					name="og:site_name"
					content="Mineral Swim by Maytronics - Experience The Dead Sea"
				/>
				<html lang="en-AU" />
				<meta name="theme-color" content="#008599" />
				<meta
					name="apple-mobile-web-app-status-bar-style"
					content="black-translucent"
				/>
				<meta name="application-name" content="Mineral Swim" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />
				<link rel="prefetch" href={Logo}></link>
				<link rel="prefetch" href="./fonts/inter/index.css"></link>
				<link rel="prefetch" href="./fonts/flexo/index.css"></link>
			</Helmet>
			
			<div>
			{children}
			</div>
			<Footer setEmail={setEmail} setFormType={setFormType}  setIsFormModalOpen={setIsFormModalOpen}/>
		</div>
		</div>
		</ScrollProvider>
		</>
		)
}

Layout.propTypes = {
	data: PropTypes.any,
	children: PropTypes.node.isRequired,
}

export default Layout
