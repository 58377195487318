import React, { Component, useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Formik } from 'formik'
import * as Yup from 'yup'
import qs from 'qs'
import Brochure from '../../images/MineralSwimV2_Brochure_ONLINEonly.pdf';
import { XIcon } from '@heroicons/react/solid';

function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

function validationIcons(valid) {
	if (valid === true) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-exclamation-triangle" />
			</span>
		)
	}
	if (valid === false) {
		return (
			<span className="icon is-small is-right">
				<i className="fas fa-check" />
			</span>
		)
	}
	return ''
}

const FormSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Too short.')
		.max(50, 'Too long.')
		.required('Please provide a name.'),
	lastName: Yup.string()
		.min(2, 'Too short.')
		.max(50, 'Too long.')
		.required('Please provide a last name.'),
	email: Yup.string()
		.email('Invalid email address.')
		.required('Please provide an email address.'),
	mobile: Yup.string().matches(
		/^(?:\+?61|0)4 ?(?:(?:[01] ?[0-9]|2 ?[0-57-9]|3 ?[1-9]|4 ?[7-9]|5 ?[018]) ?[0-9]|3 ?0 ?[0-5])(?: ?[0-9]){5}$/,
		'Invalid number.'
	),
	postcode: Yup.string()
		.matches(/^[0-9]{4}/, 'Invalid Australian postcode.')
		.required('Please provide an postcode.'),
})

function downloadFile(url, fileName) {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName || 'download';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

class BrochureForm extends Component {
	componentDidMount() {
		const script = document.createElement('script')
		script.src = 'https://www.google.com/recaptcha/api.js'
		script.async = true
		script.defer = true
		document.body.appendChild(script)
	}
	render() {
		return (
			<div className="brochureForm">
				<div className="relative container">
				<span className='cursor-pointer absolute top-0 right-0 p-1 text-red-600 hover:bg-gray-300 rounded-sm' onClick={()=>{this.props.formType==="indexBrochure"?navigate("/"):null ;this.props.setIsFormModalOpen(false); this.props.setFormType(""); }}><XIcon className="h-6 w-6 text-red-600" /></span>
					<Formik
						initialValues={{
							'important-name-field': '',
							firstName: '',
							lastName: '',
							email: this.props && this.props.formType === "getOnTheList"  && this.props.email ||'',
							mobile: '',
							postcode: '',
						}}
						validationSchema={FormSchema}
						onSubmit={(
							values,
							{ setSubmitting, setErrors, setStatus, setTouched, setValues }
						) => {
							setSubmitting(true)
							const body = {
								orgid: '00D58000000JkgU',
								retURL:
									'https://www.mineralswim.com/book-a-consultation/?form=contact',
								subject: 'Mineral Swim - Brochure Download',
								'00N4H00000E2wq9':
									'https://www.mineralswim.com/',
								recordType: '0124H0000006NyI',
								origin: this.props.formType === "getOnTheList"?'Join Mailing List WebSite':'Download Brochure WebSite',
								name: `${values.firstName} ${values.lastName}`,
								email: values.email,
								phone: values.mobile,
								'00N4H00000E2wqC': values.postcode,
								description: this.props.formType === "getOnTheList"?'Join Mailing List':"Download Brochure",
								'00N4H00000E2wq7': 'Australia',
							}
							if (values['important-name-field']) {
								return setSubmitting(false)
							}
							fetch(
								'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
								{
									method: 'POST',
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
									body: qs.stringify(body),
								}
							)
								.then(res => {console.log("res")})
								.catch(err => {console.log("err")})
							setTimeout(() => {																
								!this.props.formType &&	this.props.formType !== "getOnTheList" ? downloadFile(Brochure, 'MineralSwimV2_Brochure.pdf') : null
								setErrors('')
								setTouched('')
								setValues({
									firstName: '',
									lastName: '',
									email: '',
									mobile: '',
									postcode: '',
								})
							if (this.props.formType &&	this.props.formType === "indexBrochure" ) {
								downloadFile(Brochure, 'MineralSwimV2_Brochure.pdf')
								navigate('/')
							}	
							this.props.formType &&	this.props.formType === "getOnTheList" ? setStatus('success') : null
								setSubmitting(false)
								this.props.formType &&	this.props.formType === "getOnTheList" ? setTimeout(() => {
									this.props.setIsFormModalOpen(false)
								}, 2000) :	this.props.setIsFormModalOpen(false)

								this.props.setFormType("")
							}, 2000);							
						}}
						render={({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setStatus,
							status,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} className="max-w-screen-md mx-auto" style={{minWidth:"300px", width:"80%"}}>
									
								<h4 className='text-center text-lg mx-10 mb-5'>Fill the information below to <br/> {this.props.formType==="getOnTheList"?'Join our Mailing List':'Download Brochure'}</h4>
								<div className="field is-horizontal">
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="first-name"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="first-name"
													name="firstName"
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.firstName}
													placeholder="First Name"
													className={classNames(
														touched.firstName &&
															errors.firstName &&
															`is-danger`,
														values.firstName &&
															!errors.firstName &&
															`is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-user" />
												</span>
												{touched.firstName &&
													errors.firstName &&
													validationIcons(true)}
												{values.firstName &&
													!errors.firstName &&
													validationIcons(false)}
											</div>
											{touched.firstName && errors.firstName && (
												<p
													className="help is-danger"
													id="first-name-helper-text"
												>
													{errors.firstName}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="last-name"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="last-name"
													name="lastName"
													type="text"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.lastName}
													placeholder="Last Name"
													className={classNames(
														touched.lastName && errors.lastName && `is-danger`,
														values.lastName && !errors.lastName && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-user" />
												</span>
												{touched.lastName &&
													errors.lastName &&
													validationIcons(true)}
												{values.lastName &&
													!errors.lastName &&
													validationIcons(false)}
											</div>
											{touched.lastName && errors.lastName && (
												<p
													className="help is-danger"
													id="last-name-helper-text"
												>
													{errors.lastName}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="field is-horizontal">
									<div className="field-body">
										<div className="field is-expanded" aria-describedby="email">
											<div className="control has-icons-left has-icons-right">
												<input
													id="email"
													name="email"
													type="email"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.email}
													placeholder="you@example.com"
													className={classNames(
														touched.email && errors.email && `is-danger`,
														values.email && !errors.email && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-envelope" />
												</span>
												{touched.email && errors.email && validationIcons(true)}
												{values.email &&
													!errors.email &&
													validationIcons(false)}
											</div>
											{touched.email && errors.email && (
												<p className="help is-danger" id="email-helper-text">
													{errors.email}
												</p>
											)}
										</div>
									</div>
								</div>
									<div className="field is-horizontal">
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="mobile"
											>
												<div className="control has-icons-left has-icons-right">
													<input
														id="mobile"
														name="mobile"
														type="tel"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.mobile}
														placeholder="Mobile"
														className={classNames(
															touched.mobile && errors.mobile && `is-danger`,
															values.mobile && !errors.mobile && `is-success`,
															`input`
														)}
													/>
													<span className="icon is-small is-left">
														<i className="fas fa-phone" />
													</span>
													{touched.mobile &&
														errors.mobile &&
														validationIcons(true)}
													{values.mobile &&
														!errors.mobile &&
														validationIcons(false)}
												</div>
												{touched.mobile && errors.mobile && (
													<p className="help is-danger" id="mobile-helper-text">
														{errors.mobile}
													</p>
												)}
											</div>
										</div>
									</div>
								<div className="field is-horizontal">
									<div className="field-body">
										<div
											className="field is-expanded"
											aria-describedby="postcode"
										>
											<div className="control has-icons-left has-icons-right">
												<input
													id="postcode"
													name="postcode"
													type="tel"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.postcode}
													placeholder="Postcode"
													className={classNames(
														touched.postcode && errors.postcode && `is-danger`,
														values.postcode && !errors.postcode && `is-success`,
														`input`
													)}
												/>
												<span className="icon is-small is-left">
													<i className="fas fa-home" />
												</span>
												{touched.postcode &&
													errors.postcode &&
													validationIcons(true)}
												{values.postcode &&
													!errors.postcode &&
													validationIcons(false)}
											</div>
											{touched.postcode && errors.postcode && (
												<p className="help is-danger" id="postcode-helper-text">
													{errors.postcode}
												</p>
											)}
										</div>
									</div>
								</div>
								<p className="has-text-centered">
									<button
										type="submit"
										disabled={isSubmitting}
										className={classNames(
											isSubmitting ? `bg-gray-500`:`  bg-blue-500 hover:bg-blue-400`,
											`text-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white`
										)}
									>
										{isSubmitting ? "Loading" :"Submit"}
									</button>
								</p>
								<div
									className={status === 'success' ? `is-active modal` : `modal`}
									id="form-success"
								>
									<div
										role="button"
										tabIndex="-20"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content text-center">
												<h1 id="success-message-title">
													Success
												</h1>
												<p>													
													We will be in touch soon.
												</p>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
									/>
								</div>
								<div
									className={
										status !== 'success' && status ? `is-active modal` : `modal`
									}
									id="form-failure"
								>
									<div
										role="button"
										tabIndex="-21"
										className="modal-background"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
									<div className="modal-content">
										<div className="box">
											<div className="content">
												<h1 id="failure-message-title">
													Something went wrong{' '}
													<span
														role="img"
														aria-labelledby="failure-message-title"
													>
														😞
													</span>
												</h1>
												<p>
													Form is invalid, please check your details and try
													again.
												</p>
												<pre>{status}</pre>
											</div>
										</div>
									</div>
									<button
										className="modal-close is-large"
										aria-label="close"
										onClick={() => setStatus('')}
										onKeyPress={() => setStatus('')}
									/>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
		)
	}
}

BrochureForm.propTypes = {
	title: PropTypes.string,
}

export default BrochureForm
